<template>
  <swiper
    :slides-per-view="4"
    :space-between="15"
    :slidesPerGroup="1"
    :loop="true"
    :autoplay="{ delay: 2500}"
    navigation
    :pagination="{ clickable: true }"
    @swiper="onSwiper"
    @slideChange="onSlideChange"
    class="height"
  >
    <swiper-slide v-for="(poster, key) in posters" :key="key">
      <Image :imageName="poster.imageUrl" height="180" theClass="img-fluid slider_item" @click="goto(poster.project.id)" />
    </swiper-slide>
  </swiper>
</template>
<script>
// Import Swiper Vue.js components
import SwiperCore, { Navigation, Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/vue'
import { defineAsyncComponent } from 'vue'

// install Swiper modules
SwiperCore.use([Navigation, Pagination])

export default {
  name: 'NewSlider',
  props: ['posters'],
  components: {
    Swiper,
    SwiperSlide,
    Image: defineAsyncComponent(() => import('./Image.vue'))
  },
  data () {
    return {
      url: process.env.VUE_APP_URL_BASE
    }
  },
  methods: {
    goto (val) {
      window.scroll(0, 0)
      this.$router.push('/project/' + val)
    }
  }
}
</script>

<style scoped>
.slider_item {
    object-fit: cover;
    width: 100%;
    height: 180px;
    border-radius: 17px;
  }
  .height {
    height: 200px;
  }
</style>
